<template>
  <div class="box">
    <img
      class="m_tb_20 pointer"
      :src="banner"
      alt=""
      width="100%"
      @click="toUploadWorks_click"
    />
    <el-breadcrumb class="m_b_10" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        :to="{
          name: 'regionResource',
          params: { classify: '1555104854509219841', name: '劳动教育' },
        }"
      >
        劳动教育
      </el-breadcrumb-item>
      <el-breadcrumb-item>经验展示</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flex_row m_b_20">
      <div class="tree-box p_in_10 bg_fff br_5 m_r_20">
        <el-tree
          accordion
          :data="leftTreeData"
          node-key="id"
          :default-expanded-keys="['1603288457629736962']"
          @node-click="treeNode_click"
          ref="tree"
          highlight-current
        >
        </el-tree>
      </div>
      <div class="content-box p_in_10 bg_fff br_5">
        <template v-if="courseList.length > 0">
          <div
            class="course p_tb_10 flex_row pointer"
            v-for="item in courseList"
            :key="item.id"
            @click="toDetail_click(item)"
          >
            <img class="m_lr_10" :src="course" alt="" />
            <div style="align-items: center">
              <div class="m_b_20">
                <span class="fs_18 m_r_20 fw_bold">{{ item.name }}</span>
                <el-tag
                  size="mini"
                  :type="colorJSON[item.type]"
                  style="vertical-align: bottom"
                >
                  {{ typeJSON[item.type] }}
                </el-tag>
              </div>
              <div class="fs_12">
                <!--              <span class="m_r_20">王老师</span>
                              <span class="m_r_20">成都市第30幼儿园</span>
                              <span class="m_r_20">1254人学习</span>-->
                <span>{{ item.browseNumber }} 次浏览</span>
              </div>
            </div>
          </div>
        </template>
        <el-empty description="描述文字" v-else></el-empty>
        <el-pagination
          style="text-align: center; margin-top: 10px"
          hide-on-single-page
          :current-page.sync="params.current"
          @current-change="current_change"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import banner from "assets/image/Group 640.png";
import dingwei from "assets/image/footerIcon1.png";
import teacher from "assets/image/footerIcon4.png";
import course from "assets/image/Group 651.png";
import { getResourceClassifyTree, getResourceList } from "@/api/resourceCenter";
import { saveResourceLearn } from "@/api/resourceCenter/region";
import { sessionGet } from "utils/local";
import { getPhoneEncrypt } from "../../../../../../api/resourceCenter";
export default {
  name: "schoolExperience",
  data() {
    return {
      banner,
      dingwei,
      teacher,
      course,
      leftTreeData: [],
      selected: "",
      params: {
        contentsId: "",
        current: 1,
        size: 10,
        notShowEnclosure: 1,
      },
      courseList: [],
      total: 0,
      colorJSON: {
        1: "warning",
        2: "danger",
        3: "primary",
        4: "success",
      },
      typeJSON: {
        1: "文档",
        2: "音频",
        3: "视频",
        4: "图片",
      },
      loading: false,
    };
  },
  async mounted() {
    let type = this.$route.query.type;
    let params = this.$route.params;
    this.selected =
      type === "course" ? "1603288609409015809" : "1603288561874968578";
    if (params.selected) {
      this.selected = params.selected;
    }

    if (params.current) {
      await this.getResource();
      this.params.current = params.current ? Number(params.current) : 1;
    }
    await this.init();
  },
  methods: {
    async init() {
      await this.getTree();
      await this.getResource();
    },
    async getResource() {
      this.loading = true;
      this.params.contentsId = this.selected;
      await getResourceList(this.params).then((res) => {
        this.courseList = res.data.records;
        this.total = res.data.total;
      });
    },
    async getTree() {
      await getResourceClassifyTree({ id: "1603288457629736962" }).then(
        (res) => {
          this.leftTreeData = res.data;
          this.$nextTick(() => {
            this.$refs["tree"].setCurrentKey(this.selected);
          });
        }
      );
    },
    async treeNode_click(data) {
      if (data.classifyId === -1) return;
      this.params.current = 1;
      this.selected = data.id;
      await this.getResource();
    },
    toDetail_click(data) {
      let userInfo = sessionGet("userInfo");
      if (userInfo) {
        saveResourceLearn({ resourcesId: data.id });
      }
      this.$router.push({
        path: "/resource/schoolExperienceDetail",
        query: {
          id: data.id,
          current: this.params.current,
          selected: this.selected,
        },
      });
    },
    async current_change(current) {
      this.params.current = current;
      await this.getResource();
    },
    toUploadWorks_click() {
      let userInfo = sessionGet("userInfo");
      if (!userInfo) return this.$message.warning("请登录");
      getPhoneEncrypt({ id: userInfo.user_id }).then((res) => {
        if (res.code !== 200) return this.$message.error(res.msg);
        window.open(
          `https://cdwh.wuhousmartedu.com/portalJump/toActivityGuide/${res.data}`
        );
      });
    },
  },
};
</script>

<style scoped>
.box {
  width: 1200px;
  margin: auto;
}

.tree-box {
  width: 200px;
}

.content-box {
  width: 100%;
}

.course {
  height: 140px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.course:last-child {
  border-bottom: 1px solid #fff;
}

.course > img {
  height: 100%;
}
</style>
